<template>
  <div class="setlang-page">
    <layout :header="$t('LANGUAGE')" @goback="$router.push('/Setting')">
      <template v-slot:content>
        <v-col class="card-setlang" cols="12">
          <v-row class="setlang-item px-2 py-2" dense @click="swLang('en')">
            <v-col cols="9" align-self="center">
              <div class="title-his">English</div>
            </v-col>
            <v-col cols="3" class="text-right d-flex align-center justify-end">
                <v-icon class="check-i" medium v-if="$i18n.locale == 'en'">check</v-icon>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="card-setlang" cols="12">
          <v-row class="setlang-item px-2 py-2" dense @click="swLang('th')">
            <v-col cols="9" align-self="center">
              <div class="title-his">ภาษาไทย</div>
            </v-col>
            <v-col cols="3" class="text-right d-flex align-center justify-end">
                <v-icon class="check-i" medium v-if="$i18n.locale == 'th'">check</v-icon>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from "../../components/layout_1";
export default {
  name: "SetLanguage",
  components: {
    layout: Layout,
  },
  data: () => ({}),
  methods: {
    swLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("casa_lang", lang);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";

.setlang-page {
  height: 100%;
  .card-setlang:not(:last-child) {
    padding-bottom: 10px;
  }

  .card-setlang {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: center;

    .setlang-item {
      height: 56px;
      border-radius: 8px;
      background-color: $accent-color;
      max-width: 600px;

      .title-his {
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }

      .check-i {
          font-weight: 800;
          color: $primary-color;
      }
    }

    .text-right {
      height: 100%;
      position: relative;
    }
  }
}
</style>